import { defineNuxtPlugin } from '#app/nuxt'
import { LazyExtendedWysiwyg, LazyInputWithDropdown, LazySideScroll, LazySimpleSwiper, LazyDebugOutput, LazyLoadProgress, LazyLoadingIcon, LazyReadMore, LazyTableContent, LazyAccordionBlock, LazyArticlePage, LazyBrandListBlock, LazyBrandListingPage, LazyBrandPage, LazyButton, LazyCategoryListingBlock, LazyCategoryVisualListingBlock, LazyCheckoutPage, LazyCheckoutResult, LazyCodeSnippet, LazyConfig, LazyCoverBlock, LazyCustomerServiceBlock, LazyEditorialProductBlock, LazyErrorPage, LazyEventBlock, LazyEventEntry, LazyFormBlock, LazyGlobalFooter, LazyHeaderData, LazyIconBoxBlock, LazyIconListBlock, LazyImageBlock, LazyImageGalleryBlock, LazyMarqueeBannerBlock, LazyMarqueeVisualBannerBlock, LazyNestableNav, LazyNestableNavFooter, LazyNewsletterSignup, LazyPage, LazyProductListWithImage, LazyProductPage, LazyQuoteBlock, LazySalesToolsSettings, LazySectionHeading, LazySiteNoticeSettings, LazyStatisticsBlock, LazyTextImageVideoBlock, LazyTextPromoBlock, LazyVideoPlayer, LazyWysiwyg, LazyArticleKeyword, LazyArticleListing, LazyArticleListingCard, LazyBylineBlock, LazyEmployeeCard, LazyEmployeeCardGroup, LazyEmployeeListing, LazyEmployeeTab, LazyFeatureList, LazyPromoBlock, LazyPromoGrid, LazyTableBlock } from '#components'
const lazyGlobalComponents = [
  ["ExtendedWysiwyg", LazyExtendedWysiwyg],
["InputWithDropdown", LazyInputWithDropdown],
["SideScroll", LazySideScroll],
["SimpleSwiper", LazySimpleSwiper],
["DebugOutput", LazyDebugOutput],
["LoadProgress", LazyLoadProgress],
["LoadingIcon", LazyLoadingIcon],
["ReadMore", LazyReadMore],
["TableContent", LazyTableContent],
["AccordionBlock", LazyAccordionBlock],
["ArticlePage", LazyArticlePage],
["BrandListBlock", LazyBrandListBlock],
["BrandListingPage", LazyBrandListingPage],
["BrandPage", LazyBrandPage],
["Button", LazyButton],
["CategoryListingBlock", LazyCategoryListingBlock],
["CategoryVisualListingBlock", LazyCategoryVisualListingBlock],
["CheckoutPage", LazyCheckoutPage],
["CheckoutResult", LazyCheckoutResult],
["CodeSnippet", LazyCodeSnippet],
["Config", LazyConfig],
["CoverBlock", LazyCoverBlock],
["CustomerServiceBlock", LazyCustomerServiceBlock],
["EditorialProductBlock", LazyEditorialProductBlock],
["ErrorPage", LazyErrorPage],
["EventBlock", LazyEventBlock],
["EventEntry", LazyEventEntry],
["FormBlock", LazyFormBlock],
["GlobalFooter", LazyGlobalFooter],
["HeaderData", LazyHeaderData],
["IconBoxBlock", LazyIconBoxBlock],
["IconListBlock", LazyIconListBlock],
["ImageBlock", LazyImageBlock],
["ImageGalleryBlock", LazyImageGalleryBlock],
["MarqueeBannerBlock", LazyMarqueeBannerBlock],
["MarqueeVisualBannerBlock", LazyMarqueeVisualBannerBlock],
["NestableNav", LazyNestableNav],
["NestableNavFooter", LazyNestableNavFooter],
["NewsletterSignup", LazyNewsletterSignup],
["Page", LazyPage],
["ProductListWithImage", LazyProductListWithImage],
["ProductPage", LazyProductPage],
["QuoteBlock", LazyQuoteBlock],
["SalesToolsSettings", LazySalesToolsSettings],
["SectionHeading", LazySectionHeading],
["SiteNoticeSettings", LazySiteNoticeSettings],
["StatisticsBlock", LazyStatisticsBlock],
["TextImageVideoBlock", LazyTextImageVideoBlock],
["TextPromoBlock", LazyTextPromoBlock],
["VideoPlayer", LazyVideoPlayer],
["Wysiwyg", LazyWysiwyg],
["ArticleKeyword", LazyArticleKeyword],
["ArticleListing", LazyArticleListing],
["ArticleListingCard", LazyArticleListingCard],
["BylineBlock", LazyBylineBlock],
["EmployeeCard", LazyEmployeeCard],
["EmployeeCardGroup", LazyEmployeeCardGroup],
["EmployeeListing", LazyEmployeeListing],
["EmployeeTab", LazyEmployeeTab],
["FeatureList", LazyFeatureList],
["PromoBlock", LazyPromoBlock],
["PromoGrid", LazyPromoGrid],
["TableBlock", LazyTableBlock],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
