<template>
  <div class="layout-container desk:max-w-620">
    <div class="w-full mt-80 text-center mb-80">
      <img
        src="/logos/bywe-inv.svg"
        class="w-160 inline-block"
        :alt="$t('siteName')"
      >
    </div>
    <div class="type-headline-lg mb-8">{{ $t('error500.headline') }}</div>
    <div class="type-xs desk:type-base mb-24">{{ $t('error500.subheadline') }}</div>
    <div class="w-full text-center mb-40">
      <button class="btn inline-block" @click="handleError">{{ $t('error500.button') }}</button>
    </div>
    <div class="bg-lighter rounded-6 w-full p-16 mb-64">
      <div class="type-xs-medium desk:type-sm-medium mb-12">{{ $t('error500.tryHeadline') }}</div>
      <div class="wysiwyg">
        <ul class="type-xs" v-html="$t('error500.try')" />
      </div>
    </div>

    <div class="bg-lighter rounded-6 w-full text-10 p-16">
      <div>Code: {{ error.statusCode }}</div>
      <div>Message: {{ error.statusMessage }}</div>
      <div class="overflow-auto">
        Stack: <div v-html="error.stack" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  error: Object,
});
const handleError = () => clearError({ redirect: '/' });

</script>

<style scoped lang="postcss">
</style>
