
export default function useStoryblokAdmin() {
  const isInStoryblokAdmin = (): boolean => {
    const route = useRoute();
    if (route.query && route.query._storyblok && route.query._storyblok !== '') {
      return true;
    }
    return false;
  };

  const storyblokReleaseId = () => {
    const route = useRoute();
    return route.query?._storyblok_release ?? null;
  };

  return {
    isInStoryblokAdmin,
    storyblokReleaseId,
  };
}
