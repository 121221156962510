<template>
  <NuxtLayout>
    <NuxtLoadingIndicator color="#94FF89" />
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { Head } from '@unhead/schema';
import { useUiStore } from '~/store/ui';
import domainMapper from '~/util/domain-mapper';
import { useProductStore } from '~/store/product';
import { configure } from 'vee-validate';
import useStatefulCookie from '~/composeables/useStatefulCookie';
import { pushToDataLayer } from '~/composeables/useTracking';
import aa from 'search-insights';
import useStoryblokAdmin from '~/composeables/useStoryblokAdmin';
const { $t } = useNuxtApp();

// Global setup for vee-validate - only validate on submit
// see: https://vee-validate.logaretm.com/v4/guide/components/validation/#customizing-validation-triggers
configure({
  validateOnBlur: false,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: false,
});

const uiStore = useUiStore();

const { slug } = useRoute().params;
let isSettings = false;
if (slug) {
  isSettings = (slug.includes('sitesettings') && !slug.includes('error-404'));
}
const layout = isSettings ? 'admin-preview' : 'default';

// redirects page settings to frontpage
const runtimeConfig = useRuntimeConfig();
const { isInStoryblokAdmin } = useStoryblokAdmin();
const { $manualUrl } = useNuxtApp();
if (isSettings && !isInStoryblokAdmin() && runtimeConfig.public.environment !== 'development') {
  navigateTo($manualUrl('/'));
}

const head = {} as Head;
head.titleTemplate = (titleChunk) => {
  return titleChunk ? `${titleChunk} | ${$t('siteName')}` : $t('siteName');
};
head.meta = [];
head.link = [];

head.link.push({ rel: 'apple-touch-icon', sizes: '180x180', href: '/favicons/apple-touch-icon.png' });
head.link.push({ rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicons/favicon-32x32.png' });
head.link.push({ rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicons/favicon-16x16.png' });
head.link.push({ rel: 'manifest', href: '/favicons/site.webmanifest' });
head.link.push({ rel: 'mask-icon', href: '/favicons/safari-pinned-tab.svg', color: '#94ff89' });
head.link.push({ rel: 'shortcut icon', href: '/favicons/favicon.ico' });

head.meta.push({ name: 'msapplication-TileColor', content: '#ffffff' });
head.meta.push({ name: 'theme-color', content: '#ffffff' }); // theme color for ios
head.meta.push({ name: 'msapplication-config', content: '/favicons/browserconfig.xml' });
head.meta.push({ name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1' });

if (!head.script) {
  head.script = [];
}

const nuxtApp = useNuxtApp();
let hostname = '';
if (process.server) {
  hostname = nuxtApp?.ssrContext?.event?.node?.req?.headers?.host ?? '';
} else {
  hostname = window.location.host;
}
/**
 * Adding scripts with different domain keys
 */
let foundDomain = domainMapper.find((d: { domain: any; }) => d.domain === hostname);
// Then check path to make it work in staging etc
const path = useRoute().path?.split('/').filter((str) => str.length).shift();
const foundPath = domainMapper.find((d: { storyblokBasePath: string | undefined; }) => d.storyblokBasePath === path);

if (!['preview', 'production', 'preproduction'].includes(foundDomain?.environment ?? '') && path && path.length && foundPath && foundDomain?.domain === foundPath?.domain) {
  foundDomain = foundPath;
}
/**
 * Preview pages inside storyblok admin or dev domains
 */
if (isSettings || foundDomain?.noRobots) {
  head.meta.push({ name: 'robots', content: 'noindex,nofollow' });
}

head.link.push({ rel: 'canonical', href: 'https://' + foundDomain?.domain + useRoute().path  });

if (foundDomain?.domain?.includes('beta')) {
  head.meta.push({ name: 'robots', content: 'noindex' });
  head.meta.push({ name: 'robots', content: 'noindex,nofollow' });
}

if (foundDomain?.locale) {
  if (!head.htmlAttrs) {
    head.htmlAttrs = {};
  }
  head.htmlAttrs.lang = foundDomain.locale;
}

useHead(head);

/**
 * Navigation guard
 * Disables all links if we are inside storyblok admin, since it messes up the admin
 *
 * On navigation:
 * - close all modals
 * - reset datalayer
 */
const router = useRouter();
const route = useRoute();
router.beforeEach(() => {
  uiStore.closeAllModal();
  uiStore.setTemporaryError('');
  uiStore.setTemporarySuccessMsg('');
  // sends tracked products from prev. page
  useProductStore().sendBatch();
  // resets data layer for new page
  pushToDataLayer({ ecommerce: null });

  if (route.query?._storyblok) {
    console.warn('You are inside storyblok admin. Navigation disabled');
    return false;
  }

  document.body.classList.remove('noScroll');
});

/**
 * Sets btb or b2c modal
 */
onMounted(()=> {
  // init algolia insights
  aa('init', {
    appId: 'NGL8V2D1S4',
    apiKey: 'cbd9b16468b0f251ba85993bf64c08f5',
  });
});

const generateRandom = (min: number, max: number) => {
  return Math.floor(min + Math.random()*(max - min + 1));
};

const randLetter = () => {
  return Math.random() < .5 ? String.fromCharCode(generateRandom(65,90)) : String.fromCharCode(generateRandom(97,122));
};

/**
 * Creates an random id that we can use for tracking, used for algolia id
 */
const browserTrackingCookie = useStatefulCookie('randomId', { maxAge: 3600 * 24 * 365 });
if (!browserTrackingCookie.value) {
  browserTrackingCookie.value =  randLetter() + randLetter() + generateRandom(0,10) + generateRandom(0,10) + randLetter() + randLetter() + generateRandom(0,10) + generateRandom(0,10);
}

</script>
<style>
.page-enter-active,
.page-leave-active {
    transition: all 0.3s;
}
.page-enter-from,
.page-leave-to {
    opacity: 0.5;
    //filter: blur(2px);
}
</style>
