/**
 * @important - typing for nuxt plugins is in index.d.ts
 */

export default defineNuxtPlugin(() => {
  /* Resolves the storyblok component */
  // It is needed so that the components are rendered on the server instead of the client.
  function resolveStoryBlokComponent(story: { component: any; content: { component: any; }; }) {
    if (!story) { return null; }
    return resolveComponent(story?.component || story?.content?.component);
  }

  return {
    provide: {
      resolveStoryBlokComponent,
    },
  };
});
