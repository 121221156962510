
import { useGlobalContentStore } from '~/store/globalContent';
import { ISbStoryData } from 'storyblok-js-client';
import { ISbLinkURLObject } from 'storyblok-js-client/src/interfaces';

export type SBReplacedLinks = {
  stories: ISbStoryData[],
  cv: number
  links: (ISbStoryData | ISbLinkURLObject)[]
  rels: ISbStoryData[]
}

/**
 * @todo - this works when getting multiple pages to but returns an array
 * Dont know how to typescript that
 */
export default function useLinkReplacer() {
  const replaceLinksInStoryBlokResponse = (obj: object): SBReplacedLinks => {
    const globalContent = useGlobalContentStore();

    if (globalContent.isSubFolder) {
      return <SBReplacedLinks>obj;
    }

    const clonedObj = JSON.parse(JSON.stringify(obj)
    // First replace all .se/.dk etc
      .replaceAll(`.${globalContent.getStoryblokBasePath}`, '<<rep>>')
      .replaceAll(`${globalContent.getStoryblokBasePath}/`, '/')
      .replaceAll('<<rep>>', `.${globalContent.getStoryblokBasePath}`)
      .replaceAll('"//', '"/')
    );
    obj = Object.assign(obj, clonedObj);
    return <SBReplacedLinks>obj;
  };

  const replaceLinksInPath = (path: string) : string => {
    const globalContent = useGlobalContentStore();
    if (globalContent.isSubFolder) {
      return path;
    }
    const route = useRoute();
    const isInStoryblokAdmin = (route.query && route.query._storyblok && route.query._storyblok !== '');
    if (!isInStoryblokAdmin && path && path.startsWith(`${globalContent.getStoryblokBasePath}/`)) {
      path = path.replace(`${globalContent.getStoryblokBasePath}/`, '');
    }
    return isInStoryblokAdmin ? path : `${globalContent.getStoryblokBasePath}/${path}`;
  };

  const manualUrl = (url: string|null|undefined) : string => {
    if (!url) {
      return '';
    }
    const globalContent = useGlobalContentStore();
    if (url.startsWith(`${globalContent.getStoryblokBasePath}/`)) {
      return `/${url}`;
    }
    return `${globalContent.getPrependPathForManualLinks}${url.startsWith('/') ? '' : '/'}${url}`;
  };

  const productUrl = (url: string) : string => {
    const globalContent = useGlobalContentStore();
    return `${globalContent.getPrependPathForManualLinks}/${globalContent.getProductBasePath}/${url}`;
  };

  const accountUrl = (url?: string) : string => {
    const globalContent = useGlobalContentStore();
    return `${globalContent.getPrependPathForManualLinks}/account/${url}`;
  };

  const salesUrl = (url?: string) : string => {
    const globalContent = useGlobalContentStore();
    return `${globalContent.getPrependPathForManualLinks}/salestool/${url}`;
  };

  return {
    replaceLinksInStoryBlokResponse,
    replaceLinksInPath,
    productUrl,
    accountUrl,
    salesUrl,
    manualUrl,
  };
}
