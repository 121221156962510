import { ParametricData } from '~/constants/types/algolia';
export type ParametricModel = Parametric & ParametricData
export default class Parametric {
  _Parametric: ParametricData;
  constructor(Parametric: ParametricData) {
    this._Parametric = Parametric;
    return new Proxy(this, {
      get(target, name, receiver) {
        if (Reflect.has(target, name)) {
          return Reflect.get(target, name, receiver);
        }
        return Reflect.get(target._Parametric, name, receiver);
      },
    });
  }

  static create(arg: ParametricData) {
    return new Parametric(arg) as ParametricModel;
  }

  get pdpDisplayContent(): {image: string, label: string}[] {
    if (!this.showOnPdp) {
      return [];
    }

    switch (this._Parametric.type) {
      case 'multiple':
        return this._Parametric.multipleValues
          ?.filter(mv => mv.image?.length ?? false)
          .map(mv => {
            return {
              image: mv?.image ?? '',
              label: mv?.name ?? '',
            };
          }) ?? [];

      case 'list':
        return [{
          image: this._Parametric.listValue?.image ?? '',
          label: this._Parametric.listValue?.name ?? '',
        }];

      default:
        return [{
          image: this._Parametric?.image ?? '',
          label: this._Parametric?.name ?? '',
        }];
    }

  }

  get showOnPdp() {
    switch (this._Parametric.type) {
      case 'multiple':
        return this._Parametric.multipleValues?.some(mv => mv.image?.length) ?? false;

      case 'list':
        return !!(this._Parametric.listValue?.image?.length);

      default:
        return !!(this._Parametric.image?.length);
    }
  }

  get data(): string | { code: string; name: string }[] | null | undefined {

    switch (this._Parametric.type) {
      case 'multiple':
        return this._Parametric.multipleValues;

      case 'list':
        return this._Parametric.listValue?.name;

      default:
        return this._Parametric.value;
    }
  }
}
