import { default as _91_46_46_46slug_933Vcx1ZShpqMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[lang]]/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93USpqcDzIIPMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[lang]]/account/[...slug].vue?macro=true";
import { default as inviteSfXVgYi95IMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[lang]]/account/invite.vue?macro=true";
import { default as _91token_93uOFabIcpGpMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[lang]]/account/resetpassword/[email]/[token].vue?macro=true";
import { default as signinVoiOowqlPtMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[lang]]/account/signin.vue?macro=true";
import { default as indexofklGbdF8fMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[lang]]/account/signup/index.vue?macro=true";
import { default as successcLTV3qd6whMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[lang]]/account/signup/success.vue?macro=true";
import { default as welcomeOkQCS68cHMMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[lang]]/account/signup/welcome.vue?macro=true";
import { default as _91cartId_93PXGpH6xgwtMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[lang]]/checkout/[result]/[cartId].vue?macro=true";
import { default as indextkb5vQWXlSMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[lang]]/checkout/index.vue?macro=true";
import { default as _91basketId_93AlXtXbMwFvMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[lang]]/checkout/pointshop/[basketId].vue?macro=true";
import { default as _91_46_46_46slug_93cXDO4T7j1SMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[lang]]/salestool/[...slug].vue?macro=true";
import { default as searchpFGsRr6LujMeta } from "/opt/atlassian/pipelines/agent/build/pages/[[lang]]/search.vue?macro=true";
import { default as barcodeF5NAN4dnO3Meta } from "/opt/atlassian/pipelines/agent/build/pages/barcode.vue?macro=true";
import { default as barcodelookupRFn0mZfdOsMeta } from "/opt/atlassian/pipelines/agent/build/pages/barcodelookup.vue?macro=true";
import { default as formValidationTemplateVVH0F5HDjYMeta } from "/opt/atlassian/pipelines/agent/build/pages/formValidationTemplate.vue?macro=true";
import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as tailwindfixesJKo63NYaaBMeta } from "/opt/atlassian/pipelines/agent/build/pages/tailwindfixes.vue?macro=true";
import { default as test1g9xwIZN1aMeta } from "/opt/atlassian/pipelines/agent/build/pages/test.vue?macro=true";
export default [
  {
    name: "lang-slug",
    path: "/:lang?/:slug(.*)*",
    meta: _91_46_46_46slug_933Vcx1ZShpqMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[lang]]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "lang-account-slug",
    path: "/:lang?/account/:slug(.*)*",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[lang]]/account/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "lang-account-invite",
    path: "/:lang?/account/invite",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[lang]]/account/invite.vue").then(m => m.default || m)
  },
  {
    name: "lang-account-resetpassword-email-token",
    path: "/:lang?/account/resetpassword/:email()/:token()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[lang]]/account/resetpassword/[email]/[token].vue").then(m => m.default || m)
  },
  {
    name: "lang-account-signin",
    path: "/:lang?/account/signin",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[lang]]/account/signin.vue").then(m => m.default || m)
  },
  {
    name: "lang-account-signup",
    path: "/:lang?/account/signup",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[lang]]/account/signup/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-account-signup-success",
    path: "/:lang?/account/signup/success",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[lang]]/account/signup/success.vue").then(m => m.default || m)
  },
  {
    name: "lang-account-signup-welcome",
    path: "/:lang?/account/signup/welcome",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[lang]]/account/signup/welcome.vue").then(m => m.default || m)
  },
  {
    name: "lang-checkout-result-cartId",
    path: "/:lang?/checkout/:result()/:cartId()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[lang]]/checkout/[result]/[cartId].vue").then(m => m.default || m)
  },
  {
    name: "lang-checkout",
    path: "/:lang?/checkout",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[lang]]/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-checkout-pointshop-basketId",
    path: "/:lang?/checkout/pointshop/:basketId()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[lang]]/checkout/pointshop/[basketId].vue").then(m => m.default || m)
  },
  {
    name: "lang-salestool-slug",
    path: "/:lang?/salestool/:slug(.*)*",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[lang]]/salestool/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "lang-search",
    path: "/:lang?/search",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[[lang]]/search.vue").then(m => m.default || m)
  },
  {
    name: "barcode",
    path: "/barcode",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/barcode.vue").then(m => m.default || m)
  },
  {
    name: "barcodelookup",
    path: "/barcodelookup",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/barcodelookup.vue").then(m => m.default || m)
  },
  {
    name: "formValidationTemplate",
    path: "/formValidationTemplate",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/formValidationTemplate.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexOD9t3F2bSJMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "tailwindfixes",
    path: "/tailwindfixes",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/tailwindfixes.vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/test.vue").then(m => m.default || m)
  }
]