import Product, { ProductModel } from '~/models/product';

import { Brand, Category, ColorInfo, ParametricData, PriceInfo, ProductRaw } from '~/constants/types/algolia';
import { ProductVariantModel } from '~/models/productVariant';

import { DirectiveBinding } from 'vue';
import { getLocalCurrency } from '~/composeables/useLocalizedCurrency';
import { useCartStore } from '~/store/cart';
import { useProductStore } from '~/store/product';
import { CartApiItem, OrderProduct } from '~/constants/types/norce';
import useStatefulCookie from '~/composeables/useStatefulCookie';

type VtrackInput = {
  product: ProductRaw,
  pageName: string,
  pageId: number,
  index: number,
}

export type DataLayerProduct = {
  index?: number;
  item_brand?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category?: string;
  item_id: string;
  item_list_id?: string; // - sidans id kristian
  item_list_name?: string; // - sidans namn
  item_name: string;
  item_variant?: string; // - färg
  price?: string;
  quantity?: number,
}
export type DataLayerInput = {
  brand: Brand,
  categories: Category[],
  color?: ParametricData,
  colorInfo?: ColorInfo,
  name: string,
  partNo: string,
  price: PriceInfo,
}

export const useProductImpressionTracking = {
  mounted: (el: HTMLElement, input: DirectiveBinding<VtrackInput>)  => {
    const productStore = useProductStore();
    if (input.value.product) {
      const observer = new IntersectionObserver(
        (entries, observer) => {

          const productItem = Product.create(input.value.product);
          entries.forEach(entry => {
            const item = createDataLayerItem(productItem, 1, input.value.index);
            if (input.value.pageName) {
              item['item_list_name'] = input.value.pageName;
              item['item_list_id'] = input.value.pageId + '';
            }
            if (entry.intersectionRatio > 0 && window.dataLayer) {
              productStore.batchTrack(item);
              observer.unobserve(entry.target);
            }
          });

        }, {
          threshold: .5, // Wait til we se at least half the observed
        });
      observer.observe(el);
    } else { console.warn('Used tracking directive without value'); }
  },
};

/**
 * This replaces direct usage of window.datalayer.push,
 * adds algolia tracking id
 * algoliaUserToken: 'user-1',
 */
export const pushToDataLayer = (data: object) => {
  const browserTrackingCookie = useStatefulCookie('randomId', { maxAge: 3600 * 24 * 365 });
  const algoliaUserToken = browserTrackingCookie.value || '';
  data.algoliaUserToken = algoliaUserToken;

  if (window.dataLayer) {
    window.dataLayer.push(data);
  } else {
    console.warn('attempted useTracking pushDataLayer without datalayer');
  }
};

export const sendDataLayer = (
  event: string,
  items: DataLayerProduct[],
  additionalData: object = {}
) => {
  pushToDataLayer({
    event,
    ecommerce: {
      ...additionalData,
      currency: getLocalCurrency(),
      items,
    },
  });
};

type AdditionalCartData = {
  value?: string;
  payment_type?: string;
  shipping_tier?: string;
}

/**
 * Uses the cartstore to create an even that includes items in cart
 * @param event
 * @param additionalData
 */
export const createEventFromCart = (event: string, additionalData: AdditionalCartData = {}) => {
  const cartStore = useCartStore();
  const trackProducts = [] as DataLayerProduct[];
  if (!additionalData.value && cartStore.cart.summary?.price) {
    additionalData.value = cartStore.cart.summary.price;
  }
  cartStore.cart.items.forEach((item, index) => {
    trackProducts.push(
      createDataLayerItem(
        item,
        item.quantity,
        index + 1
      )
    );
  });
  sendDataLayer(event, trackProducts, additionalData);
};

/**
 * Converts to DataLayerProduct
 */
export const createDataLayerItem = (
  product: ProductModel | ProductRaw | CartApiItem | OrderProduct | ProductVariantModel,
  quantity = 1,
  index = 1
): DataLayerProduct => {
  let color = '';
  if (product.colorInfo?.code) {
    color = product.colorInfo.code;
  }
  const output = {
    item_name: product.name,
    item_id: product.partNo,
    item_variant: color,
    price: product.priceInfo.price,
    quantity,
    index,
    item_brand: product.brand?.code || '',
  } as DataLayerProduct;

  product.categories?.map((i)=> i.name).slice(0,4).forEach((c, index)=> {
    // @ts-ignore
    output[`item_category${index > 0 ? index + 1:''}`] = c;
  });

  //console.log(output);
  return output;
};
