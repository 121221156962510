/**
 * @important - typing for nuxt plugins is in index.d.ts
 */

import { useGlobalContentStore } from '~/store/globalContent';

export default defineNuxtPlugin(() => {
  const store = useGlobalContentStore();
  return {
    provide: {
      t: (key: string | undefined, opts = {} as { [name: string]: any }) => {
        // Better to fail quietly
        if (typeof key !== 'string') {
          return '';
        }
        let message = store?.translations[key] ?? key;

        if (message === key) {
          console.warn(`Missing translation for "${key}" - locale: ${store.getLocale}`);
        }

        if (!message.length) {
          return key;
        }
        if (opts) {
          Object.keys(opts).forEach(key => {
            message = message.replaceAll(`{${key}}`, opts[key] +'');
          });
        }
        return message;
      },
    },
  };
});
